
import { defineComponent } from "vue";
import CreateEditDialog from "@/components/controls/catalogueGrid/CreateEditDialog.vue";
import mountComponentMixin from "@/mixins/MountComponentMixin";
import CatalogueSelectControl from "@/components/controls//base/CatalogueSelectControl.vue";
import CheckBoxControl from "@/components/controls/base/CheckBoxControl.vue";
import DecimalControl from "@/components/controls/base/DecimalControl.vue";
import api from "@/services/api";

export default defineComponent({
  components: {
    CreateEditDialog,
    CatalogueSelectControl,
    DecimalControl,
    CheckBoxControl,
  },
  mixins: [mountComponentMixin],
  props: {
    saveModelCallback: { required: true },
  },
  computed: {},
  data() {
    return {
      model: null,
      copyMode: false,
    };
  },
  methods: {
    onChange(e: any) {
      this.setCopyMode(true);
      api
        .get("/Requisite/GetEditModel", {
          params: {
            id: this.model.requisiteId,
          },
        })
        .then((res) => {
          this.setData(res.data);
        });
    },
    setData(data: any) {
      this.model.totalWeight = data.totalWeight;
      this.model.isActive = data.isActive;
    },
    dialog() {
      return this.$refs.dialog as any;
    },
    open(model: any, editType: any) {
      this.dialog().open(model, editType);
      this.model = this.dialog().createEditModel;
      this.setCopyMode(false);
    },
    close() {
      this.dialog().close();
    },
    save() {
      this.dialog().save();
    },
    setCopyMode(enabled: any) {
      this.copyMode = enabled;
    },
  },
});
